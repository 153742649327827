import React from 'react';
import JsonData from '../data/data.json'

const WhatsAppIcon = () => {
    const numero = JsonData.Contact.phone;
    const handleWhatsAppClick = () => {
        //aqui agrega el numero del cliente
        window.open('https://wa.me/'+524778487516, '_blank');
    };

    return (
        <div className="whatsapp-icon" onClick={handleWhatsAppClick}>
            <i className="fa fa-whatsapp" aria-hidden="true"></i>
        </div>
    );
};

export default WhatsAppIcon;
